import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  background-color: #b7c6e1;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: Georgia, serif;
  z-index: 10; /* Ensure navbar is always above other content */
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none; /* Removes underline from the link */
`;

const LogoImage = styled.img`
  height: 40px;
  margin-right: 10px;
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  span {
    height: 3px;
    width: 25px;
    background: black;
    margin-bottom: 4px;
    border-radius: 5px;
  }

  @media (max-width: 900px) {
    display: flex;
  }
`;

const NavbarLinks = styled.ul`
  display: flex;
  gap: 20px;
  margin-left: auto;

  @media (max-width: 900px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #b7c6e1;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 11; /* Higher than NavbarContainer */
    padding: 15px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const Backdrop = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9; /* Below the navbar but above other content */
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <Backdrop open={menuOpen} onClick={closeMenu} />
      <NavbarContainer ref={menuRef}>
        <LogoLink to="/" onClick={closeMenu}>
          <LogoImage src="/images/yourstartravel.png" alt="Travel Agency Logo" />
          <h1 style={{ color: 'black', fontSize: '1.5rem', margin: 0 }}>YourStarTravel</h1>
        </LogoLink>
        <Hamburger onClick={toggleMenu}>
          <span />
          <span />
          <span />
        </Hamburger>
        <NavbarLinks open={menuOpen}>
          <li><Link to="/" onClick={closeMenu} style={{ color: 'black' }}>Home</Link></li>
          <li><Link to="/services" onClick={closeMenu} style={{ color: 'black' }}>Services</Link></li>
          <li><Link to="/agents" onClick={closeMenu} style={{ color: 'black' }}>Meet Our Agents</Link></li>
          <li><Link to="/about" onClick={closeMenu} style={{ color: 'black' }}>About</Link></li>
          {/* <li><Link to="/reviews" onClick={closeMenu} style={{ color: 'black' }}>Reviews</Link></li> */}
          <li><Link to="/destinations" onClick={closeMenu} style={{ color: 'black' }}>Destinations</Link></li>
        </NavbarLinks>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
