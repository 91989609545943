import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #333; /* Dark background color */
  color: white; /* Text color */
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
`;

const FooterLink = styled(Link)`
  color: #ff5722;
  text-decoration: none;
  margin: 0 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <p>Copyright © 2024 YOUR STAR TRAVEL - All Rights Reserved.</p>
      <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
      <FooterLink to="/terms-and-conditions">Terms and Conditions</FooterLink>
      <FooterLink to="/contact">Contact Us</FooterLink> {/* Link to Contact Page */}
    </FooterContainer>
  );
};

export default Footer;
