import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import AgentsPage from './pages/AgentsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
// import BookingPage from './pages/BookingPage';
import DestinationsPage from './pages/DestinationsPage';
import ReviewsPage from './pages/ReviewsPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/Global.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/agents" element={<AgentsPage />} />
        {/* <Route path="/booking" element={<BookingPage />} /> */}
        <Route path="/about" element={<AboutPage />} />
        <Route path="/reviews" element={<ReviewsPage />} /> 
        <Route path="/destinations" element={< DestinationsPage />} />
        <Route path="/contact" element={< ContactPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
