import React, { useEffect, useState } from 'react';
import { fetchAgentsData } from '../utils/fetchAgentsData';
import styled from 'styled-components';

const AgentsPageContainer = styled.div`
  text-align: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Georgia, serif;
`;

const AgentHeader = styled.h1`
  color: #ff5722;
  margin-top: 40px;
`;

const AgentList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 40px;
`;

const AgentCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
  text-align: left;

  &:hover {
    transform: translateY(-5px);
  }
`;

const AgentName = styled.h3`
  color: #ff5722;
  margin-bottom: 10px;
`;

const AgentPicture = styled.img`
  width: 100%;
  height: auto;
  max-height: 250px;
  object-fit: cover; /* Ensures proper aspect ratio */
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const AgentContact = styled.div`
  color: #333;
  margin-bottom: 10px;
`;

const BioLink = styled.button`
  background: none;
  border: none;
  color: #ff5722;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  text-align: left;
`;

const CloseButton = styled.button`
  background: #ff5722;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: #e64a19;
  }
`;

const AgentsPage = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    const loadAgents = async () => {
      try {
        const data = await fetchAgentsData();
        const filteredAgents = data.filter(
          (agent) => agent.picture && agent.biography
        );
        setAgents(filteredAgents);
      } catch (error) {
        setError('Failed to load agents data');
        console.error('Error loading agents data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadAgents();
  }, []);

  const openModal = (agent) => {
    setSelectedAgent(agent);
  };

  const closeModal = () => {
    setSelectedAgent(null);
  };

  if (loading) {
    return <p>Loading agents...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <AgentsPageContainer>
      <AgentHeader>Meet Our Travel Agents</AgentHeader>
      <AgentList>
        {agents.map((agent, index) => (
          <AgentCard key={index}>
            <AgentPicture src={agent.picture} alt={`Image of ${agent.name}`} />
            <AgentName>{agent.name}</AgentName>
            <AgentContact>
              {agent.contact.email && <div>Email: {agent.contact.email}</div>}
              {agent.contact.phone && <div>Phone: {agent.contact.phone}</div>}
            </AgentContact>
            <BioLink onClick={() => openModal(agent)}>Bio</BioLink>
          </AgentCard>
        ))}
      </AgentList>

      {selectedAgent && (
        <ModalOverlay isOpen={!!selectedAgent}>
          <ModalContent>
            <h2>{selectedAgent.name}</h2>
            <p>{selectedAgent.biography}</p>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </AgentsPageContainer>
  );
};

export default AgentsPage;
