import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchAgentsData } from '../utils/fetchAgentsData';
import styled from 'styled-components';

const HomeContainer = styled.div`
  background-color: #F5F5F5;
  text-align: center;
  padding: 20px;
  font-family: Georgia, serif;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const LogoImage = styled.img`
  height: 100px;
  object-fit: contain;
`;

const HeroSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 500px;
  padding: 20px;
  background: url('/images/cabo.jpg') center/cover no-repeat;
  color: #000;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    height: auto;
  }
`;

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 15px;
  z-index: 1;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    background-color: rgba(175, 200, 200, 0.5);
    padding: 20px;
    border-radius: 8px;
  }
`;


const HeroHeader = styled.h1`
  font-size: 3rem;
  margin: 10px 0;
  color: white;

  @media (max-width: 768px) {
    color: white;
  }
`;

const HeroLine = styled.p`
  font-size: 2rem;
  margin: 5px 0;
  color: white;

  @media (max-width: 768px) {
    color: white;
  }
`;

const HeroButton = styled(Link)`
  padding: 10px 20px;
  background-color: #ff5722;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e64a19;
  }
`;

const TravelInsuranceLink = styled.a`
  display: block;
  margin: 20px auto;
  max-width: 80%;
  text-align: center;

  img {
    width: 100%;
    max-width: 600px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }
`;

const AgentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  padding: 40px 0;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const AgentCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px; /* Consistent width */
  height: 240; /* Consistent height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const AgentName = styled.h3`
  color: #0099cc;
  margin-bottom: 10px;
`;

const AgentTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
`;

const AgentEmail = styled.a`
  color: #0099cc;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const AgentPhone = styled.a`
  color: #0099cc;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;


const HomePage = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadAgents = async () => {
      try {
        const data = await fetchAgentsData();
        setAgents(data);
      } catch (error) {
        setError('Failed to load agents data');
        console.error('Error loading agents data:', error);
      } finally {
        setLoading(false);
      }
    };
    loadAgents();
  }, []);

  if (loading) {
    return <p>Loading agents...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <HomeContainer>
      <LogoContainer>
        <LogoImage src="/images/logo.jpg" alt="YourStarTravel Logo" />
      </LogoContainer>
      <HeroSection>
        <HeroTextContainer>
          <HeroHeader>We do the planning for you.</HeroHeader>
          <HeroLine>Explore, relax, and enjoy every moment.</HeroLine>
          <HeroLine>Let's book the vacation of your dreams!</HeroLine>
          <HeroButton to="/contact">Contact Us To Book Now</HeroButton>
        </HeroTextContainer>
      </HeroSection>
      <h1>Connect With Our Travel Specialists</h1>
      <AgentsContainer>
        {agents.map((agent, index) => (
          <AgentCard key={index}>
            <AgentName>{agent.name}</AgentName>
            <AgentTitle>{agent.title || 'Vacation Specialist'}</AgentTitle>
            <AgentEmail href={`mailto:${agent.contact.email}`}>{agent.contact.email}</AgentEmail>
            <AgentPhone >{agent.contact.phone}</AgentPhone>
          </AgentCard>
        ))}
      </AgentsContainer>
      <TravelInsuranceLink
        href="https://www.travelexinsurance.com/index.aspx?location=10-0401AgentCode&go=bp&navigation=off"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/images/insurance/travelexinsurance_468x60_3.jpg"
          alt="Get Travel Insurance with Travelex"
        />
      </TravelInsuranceLink>
    </HomeContainer>
  );
};

export default HomePage;
