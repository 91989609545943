import React from 'react';
import styled from 'styled-components';

// Styled Components
const ServicesContainer = styled.div`
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  font-family: Georgia, serif;
`;

const ServicesHeader = styled.h2`
  font-size: 3rem;
  color: #ff5722;
  margin-bottom: 20px;
`;

const ServicesDescription = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
`;

const ServiceList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row; /* Arrange items in a row on larger screens */
    flex-wrap: wrap; /* Allow wrapping to multiple lines */
    justify-content: center; /* Center the items */
  }
`;

const ServiceItem = styled.li`
  font-size: 1.1rem;
  padding: 15px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* Set a max width for better layout */
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  strong {
    color: #ff5722;
  }
`;

const ServicesPage = () => {
  return (
    <ServicesContainer>
      <ServicesHeader>Our Services</ServicesHeader>
      <ServicesDescription>Explore our range of travel services designed to make your trip unforgettable:</ServicesDescription>
      <ServiceList>
        <ServiceItem>🎉 <strong>Unique life experiences:</strong> We specialize in offering once in a lifetime experiences that will create lasting memories.</ServiceItem>
        <ServiceItem>🧳 <strong>Personalized trips:</strong> Travel is different for everyone. We want to make sure that you are getting the most from your trip by crafting the dream vacation that is perfect for you.</ServiceItem>
        <ServiceItem>🛡️ <strong>Incredible detail and service:</strong> We've got your back. In the event something does come up, our team is here to help make sure your trip is not interrupted.</ServiceItem>
      </ServiceList>
      <ServiceList>
        <ServiceItem>✈️ <strong>Flight Bookings:</strong> Find the best flight deals.</ServiceItem>
        <ServiceItem>🏨 <strong>Hotel Reservations:</strong> Book comfortable and affordable accommodations.</ServiceItem>
        <ServiceItem>🚗 <strong>Car Rentals:</strong> Rent a car for your convenience.</ServiceItem>
        <ServiceItem>🛳️ <strong>Cruise Bookings:</strong> Plan your dream cruise across exotic destinations.</ServiceItem>
        <ServiceItem>🗺️ <strong>Tour Packages:</strong> Explore curated travel packages to popular destinations.</ServiceItem>
      </ServiceList>
    </ServicesContainer>
  );
};

export default ServicesPage;
