import React, { useState } from 'react';
import styled from 'styled-components';

const AboutPageContainer = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: Georgia, serif;
`;

const Header = styled.h2`
  font-size: 3rem;
  color: black;
  margin-bottom: 40px;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 80px;
`;

const FeatureCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
`;

const FeatureHeader = styled.h3`
  color: #ff5722;
  font-size: 1.75rem;
  margin: 0;
`;

const FeatureText = styled.p`
  color: #333;
  line-height: 1.6;
  font-size: 1.25rem;
  margin-top: 10px;
  transition: max-height 0.3s ease, opacity 0.3s ease;
`;

const AboutPage = () => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setActiveFAQ(activeFAQ === index ? null : index);
  };

  const faqData = [
    {
      question: 'Why should I use a travel agent?',
      answer:
        'Even in the age of online booking, travel agents are a great source of information and discounts. A travel agent can make sure that your trip is tailored to you, assist you in case of unexpected problems, and help you anticipate potential issues in advance. Our team shares over 60 years of travel experience that will help you maximize the enjoyment of your trip. You can talk to agents that have visited most major resorts and use their firsthand knowledge to help you find the right resort for your budget. Call now to create your special vacation.',
    },
    {
      question: 'Will I pay more if I book through an agent?',
      answer:
        'We are a free service and we do not charge any fees. Our job is to find you the best rates for your vacation. We can work within your budget, and we always focus on making sure that you get a great value in return.',
    },
    {
      question: 'Do you book group travel?',
      answer:
        'Yes! Do you have an upcoming conference for your business or a student trip? We can handle the details and ensure that you have access to group seating, entrances, and discounts.',
    },
  ];

  return (
    <AboutPageContainer>
      <Header>About Us</Header>
      <FeaturesContainer>
        <FeatureCard>
          <FeatureHeader>Our Background</FeatureHeader>
          <FeatureText>
            We are seasoned travel experts dedicated to helping people book their dream vacations. With over 100 years of travel planning experience, our team knows how to deliver excellent service and memorable experiences.
          </FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureHeader>Our Services</FeatureHeader>
          <FeatureText>
            From cruises to complete travel packages, we've got you covered. Our specialists work directly with partners to secure the best deals. Just tell us where you want to go, and we’ll handle the rest!
          </FeatureText>
        </FeatureCard>
        <FeatureCard>
          <FeatureHeader>Satisfaction Guaranteed</FeatureHeader>
          <FeatureText>
            We strive to provide top-notch service. Have questions or concerns? We’re here to assist and ensure your complete satisfaction. We look forward to being your trusted travel partner for years to come.
          </FeatureText>
        </FeatureCard>
      </FeaturesContainer>
      <Header>Frequently Asked Questions</Header>
      <FeaturesContainer>
        {faqData.map((faq, index) => (
          <FeatureCard key={index} onClick={() => toggleFAQ(index)}>
            <FeatureHeader>{faq.question}</FeatureHeader>
            {activeFAQ === index && <FeatureText>{faq.answer}</FeatureText>}
          </FeatureCard>
        ))}
      </FeaturesContainer>
    </AboutPageContainer>
  );
};

export default AboutPage;
