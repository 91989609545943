import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const DestinationsContainer = styled.div`
  padding: 20px;
  text-align: center;
  font-family: Georgia, serif;
`;

const DestinationsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const ImageCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  max-width: 300px;
  max-height: 200px;
  border-radius: 8px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: scale(1.05);
  }
`;

const ImageCaption = styled.p`
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
`;

const Modal = styled.div`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
  animation: ${({ closing }) => (closing ? fadeOut : 'none')} 0.3s ease;
  padding: 20px;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 50%;
  border-radius: 8px;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 40%;
  }
`;

const ModalDescriptionBox = styled.div`
  background-color: rgba(0, 0, 0, 0.85);
  padding: 15px;
  border-radius: 8px;
  margin-top: 10px;
  max-width: 100%;
  max-height: 30%;
  overflow-y: auto;
  text-align: center;

  @media (max-width: 768px) {
    max-height: 35%;
    padding: 10px;
  }
`;

const ModalDescription = styled.p`
  font-size: 1.1rem;
  color: #fff;
  margin: 5px 0;
  line-height: 1.5;
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
`;

const NavButton = styled.button`
  background: #ff5722;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background: #e64a19;
  }
`;

const CloseButton = styled(NavButton)`
  margin: 0 auto;
`;

const DestinationsPage = () => {
  const [destinations, setDestinations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    fetch('/assets/destinations.json')
      .then((response) => response.json())
      .then((data) => {
        const groupedDestinations = data.reduce((acc, item) => {
          const group = acc[item.caption] || [];
          group.push(item);
          acc[item.caption] = group;
          return acc;
        }, {});
        setDestinations(Object.entries(groupedDestinations));
      })
      .catch((error) => console.error('Error fetching destinations:', error));
  }, []);

  const openModal = (group) => {
    setSelectedGroup(group);
    setCurrentIndex(0);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setClosing(true);
    setTimeout(() => {
      setIsModalOpen(false);
      setSelectedGroup([]);
      setClosing(false);
    }, 300);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      closeModal();
    }
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % selectedGroup.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? selectedGroup.length - 1 : prevIndex - 1
    );
  };

  return (
    <DestinationsContainer>
      <h2>Our Travel Destinations</h2>
      <p>Explore some of the beautiful destinations we've helped our customers experience:</p>
      <DestinationsGrid>
        {destinations.map(([caption, group]) => (
          <ImageCard key={caption} onClick={() => openModal(group)}>
            <Image src={group[0].src} alt={caption} />
            <ImageCaption>{caption}</ImageCaption>
          </ImageCard>
        ))}
      </DestinationsGrid>

      <Modal
        id="modal-overlay"
        isOpen={isModalOpen}
        closing={closing}
        onClick={handleOutsideClick}
      >
        {selectedGroup.length > 0 && (
          <ModalContent>
            <ModalImage
              src={selectedGroup[currentIndex].src}
              alt={selectedGroup[currentIndex].caption}
            />
            <ModalDescriptionBox>
              {selectedGroup[currentIndex].description.map((line, index) => (
                <ModalDescription key={index}>{line}</ModalDescription>
              ))}
            </ModalDescriptionBox>
            <NavigationButtons>
              {selectedGroup.length > 1 && (
                <NavButton onClick={prevImage}>Previous</NavButton>
              )}
              <CloseButton onClick={closeModal}>Close</CloseButton>
              {selectedGroup.length > 1 && (
                <NavButton onClick={nextImage}>Next</NavButton>
              )}
            </NavigationButtons>
          </ModalContent>
        )}
      </Modal>
    </DestinationsContainer>
  );
};

export default DestinationsPage;
