import React, { useState } from 'react';
import styled from 'styled-components';

const initialReviews = [
  { name: "John Doe", comment: "Amazing service! Highly recommend." },
  { name: "Jane Smith", comment: "Had a fantastic trip, thank you!" },
];

const ReviewsContainer = styled.div`
  padding: 40px;
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  font-family: Georgia, serif;
`;

const ReviewsHeader = styled.h2`
  font-size: 2.5rem;
  color: #ff5722;
  margin-bottom: 20px;
`;

const ReviewList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  
`;

const ReviewCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const ReviewForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 20px;

  input, textarea {
    width: 100%;
    max-width: 500px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  textarea {
    resize: vertical;
  }
`;

const SubmitButton = styled.button`
  background-color: #ff5722;
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e64a19;
  }
`;

const ReviewsPage = () => {
  const [reviews, setReviews] = useState(initialReviews);
  const [newReview, setNewReview] = useState({ name: '', comment: '' });

  const handleInputChange = (e) => {
    setNewReview({ ...newReview, [e.target.name]: e.target.value });
  };

  const handleReviewSubmit = () => {
    if (newReview.name && newReview.comment) {
      setReviews([...reviews, newReview]);
      setNewReview({ name: '', comment: '' });
    }
  };

  return (
    <ReviewsContainer>
      <ReviewsHeader>User Reviews</ReviewsHeader>
      
      <p>
        <a href="https://www.google.com/search?q=Your+Star+Travel" target="_blank" rel="noopener noreferrer">
          Leave a Google Review
        </a> | 
        <a href="https://www.yelp.com/biz/your-business" target="_blank" rel="noopener noreferrer">
          Leave a Yelp Review
        </a>
      </p>
      
      <ReviewForm>
        <h3>Add Your Review:</h3>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={newReview.name}
          onChange={handleInputChange}
        />
        <textarea
          name="comment"
          placeholder="Your Review"
          value={newReview.comment}
          onChange={handleInputChange}
        />
        <SubmitButton onClick={handleReviewSubmit}>Submit Review</SubmitButton>
      </ReviewForm>

      <ReviewList>
        {reviews.map((review, index) => (
          <ReviewCard key={index}>
            <strong>{review.name}</strong>
            <p>{review.comment}</p>
          </ReviewCard>
        ))}
      </ReviewList>
    </ReviewsContainer>
  );
};

export default ReviewsPage;
